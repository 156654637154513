<template>
  <section id="contact" class="contact">
    <div class="p-0">

      <div class="section-title" data-aos="zoom-in">
        <h2>{{ sectionTitle }}</h2>
        <h3>{{ sectionSubtitle }}</h3>
        <p>{{ sectionDescription }}</p>
      </div>

      <div>
        <iframe :src="mapSrc" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="container">
        <div class="row mt-5">

          <div class="col-lg-4" data-aos="fade-right">
            <div class="info">
              <address>
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>{{ locationTitle }}</h4>
                <a :href="'#!:' + locationAddress" class="">{{ locationAddress }}</a>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>{{ emailTitle }}</h4>
                <a :href="'mailto:' + emailAddress" class="">{{ emailAddress }}</a>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>{{ phoneTitle }}</h4>
                <a :href="'tel:' + phoneNumber" class="">{{ phoneNumber }}</a>
                
              </div>
            </address>
            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
            
            <form @submit.prevent="submitForm" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input v-model="name" type="text" name="name" class="form-control" id="name"
                    :placeholder="yourNamePlaceholder" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input v-model="email" type="email" class="form-control" name="email" id="email"
                    :placeholder="yourEmailPlaceholder" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input v-model="subject" type="text" class="form-control" name="subject" id="subject"
                  :placeholder=" subjectPlaceholder" required>
              </div>
              <div class="form-group mt-3">
                <textarea v-model="message" class="form-control" name="message" rows="5"
                  :placeholder="messagePlaceholder" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">{{ loadingMessage }}</div>
                <div class="error-message"></div>
                <div class="sent-message">{{ sentMessage }}</div>
              </div>
              <div class="text-center" v-if="!isFormSubmitted"><button type="submit">{{ sendMessageButton }}</button></div>
            </form>


            <div v-if="isFormSubmitted" class="sent-message">{{ sentMessage }}</div>
          </div>

        </div>

      </div>

    </div>
  </section>
</template>

<script>
import axios from 'axios';


export default {
  props: {
    //form
    sendMailUrl: {
      type: String,
      default: '',
    },
    yourNamePlaceholder: {
      type: String,
      default: 'Your Name',
    },
    yourEmailPlaceholder: {
      type: String,
      default: 'Your Email',
    },
    subjectPlaceholder: {
      type: String,
      default: 'Subject',
    },
    messagePlaceholder: {
      type: String,
      default: 'Message',
    },
    loadingMessage: {
      type: String,
      default: 'Loading',
    },
    sentMessage: {
      type: String,
      default: 'Your message has been sent. Thank you!',
    },
    sendMessageButton: {
      type: String,
      default: 'Send Message',
    },
    //Contact section
    sectionTitle: {
      type: String,
      default: 'Contact',
    },
    sectionSubtitle: {
      type: String,
      default: 'Get in touch and make your wedding day unforgettable',
    },
    sectionDescription: {
      type: String,
      default: 'Make your wedding day unforgettable with a memorable first dance choreographed by Tim, our professional dancer and 7-time NZ National Ballroom Champion. The Dance till Dawn team is dedicated to making your special day the most magical it can be. Get to know our skilled and passionate team of dancers.',
    },
    mapSrc: {
      type: String,
      default: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.535936234551!2d174.74564569509576!3d-36.75770860289194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d398b7cd43bef%3A0xdf37eedb529da9e7!2s260%20Forrest%20Hill%20Road%2C%20Forrest%20Hill%2C%20Auckland%200620!5e0!3m2!1sen!2snz!4v1706667910205!5m2!1sen!2snz',
    },
    locationTitle: {
      type: String,
      default: 'Location',
    },
    locationAddress: {
      type: String,
      default: '123 Your Street, City',
    },
    emailTitle: {
      type: String,
      default: 'Email',
    },
    emailAddress: {
      type: String,
      default: 'email@provider.com',
    },
    phoneTitle: {
      type: String,
      default: 'Call',
    },
    phoneNumber: {
      type: String,
      default: '+123123123',
    },
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      isFormSubmitted: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        // Use axios to send a POST request to your server or serverless function
        const response = await axios.post(this.sendMailUrl, {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
        });

        console.log('response,', response);

        // Check the response and update the form submission status
        if (response.status == 200) {
          this.isFormSubmitted = true;
        } else {
          console.error('Failed to send email:', response.data.error);
          // Handle the error as needed
        }
      } catch (error) {
        console.error('Error sending email:', error);
        // Handle the error as needed
      }
    },
  },
};
</script>

<style>
  /* Your styles go here */
</style>
