<template>
  <section id="about" class="about1">
    <div class="container p-0">

      <div class="row">
        <div class="col-xl-6 col-lg-6 aos-init aos-animate" data-aos="zoom-in">
          <ImageSwiper :images="images_arr" :pagination="true" :navigation="true" :controls="false" :loop="true"/>
        </div>

        <div class="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center ">
          <div class="box-heading aos-init aos-animate p-2 pt-1 mt-1" :data-aos="'fade-up'" :data-aos-delay="(100 * index).toString()">

          
            <h3>{{aboutTitle}}</h3>
            <h2 class="h3">{{aboutSubtitle}}</h2>
            <br />
            <p v-for="(row, index) in aboutDescription" :key="index">
              {{ row }}
            </p>
            <!-- <p class="lead">
              {{ aboutDescription }}
            </p> -->
          </div>
          <div class="px-2">

            <div v-for="(item, index) in iconBoxes" :key="index" class="icon-box aos-init aos-animate"
            :data-aos="'fade-up'" :data-aos-delay="(100 * index).toString()">
            <h4 class="title"><a href="">{{ item.title }}</a></h4>
            <p class="description">{{ item.description }}</p>
          </div>
        </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>
import ImageSwiper from '@/components/ImageSwiper.vue'

export default {
  props:{
    images_arr: {
      type: Array,
    },
    aboutTitle: {
      type: String,
      default: "About Me"
    },
    aboutDescription: {
      type: Array,
      // default: ["Description","desc",]
    },
    aboutSubtitle: {
      type: String,
      default: "My name"
    },
    iconBoxes: {
      type: Array,
    },
  },
  components: {
    ImageSwiper,
  }
};
</script>

<style scoped>
/* Your scoped styles go here */
</style>
