<template>
  <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="text-center">
          <h3>{{ ctaTitle }}</h3>
          <p>{{ ctaDescription }}</p>
          <a :href="ctaButtonLink" class="cta-btn">{{ ctaButtonName }}</a>
        </div>

      </div>
    </section><!-- End Cta Section -->
</template>

<script>
export default {
  props: {
    ctaTitle: {
      type: String,
      default: 'Your CTA Title',
    },
    ctaDescription: {
      type: String,
      default: 'Your CTA Description',
    },
    ctaButtonName: {
      type: String,
      default: 'Get Started',
    },
    ctaButtonLink: {
      type: String,
      default: '#',
    },
  },
};
</script>

<style>
/* Your styles go here */
</style>
