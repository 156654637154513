import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/css/style.css';
// import './assets/js/main.js';
// import 'swiper/swiper-bundle.css';

const app = createApp(App);

// Mount the app
app.mount('#app');
