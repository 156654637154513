<template>
  <section id="pricing" class="pricing">
    <div class="container">

      <div class="section-title" data-aos="zoom-in">
        <h2>{{ sectionTitle }}</h2>
        <h3>{{ sectionSubtitle }} <span>{{ sectionTitle }}</span></h3>
        <p>{{ sectionDescription }}</p>
      </div>

      <div class="row">
        <!-- First Box -->
        <div v-for="(priceOption, index) in priceOptions" :key="index" class="col-lg-4 col-md-6 mt-4 mt-md-0">
          <div :class="{ 'box': true, 'recommended': priceOption.recommended, 'popular': priceOption.popular }" :data-aos="'zoom-in'" :data-aos-delay="(index * 100).toString()">
            <h3>{{ priceOption.title }}</h3>
            <h4 v-if="priceOption.perUnit">
              <sup>{{ priceOption.currencySymbol }}</sup>{{ priceOption.price }}<span> / {{ priceOption.perUnit }}</span>
            </h4>
            <h4 v-else>
              <sup>{{ priceOption.currencySymbol }}</sup>{{ priceOption.price }}
            </h4>
            <p>{{ priceOption.description }}</p>
            <div class="btn-wrap">
              <a :href="priceOption.buttonLink" class="btn-buy">{{ priceOption.buttonName }}</a>
            </div>
            <span v-if="priceOption.recommended" class="recommended-badge">Recommended</span>
            <span v-if="priceOption.popular" class="popular-badge">Popular</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    sectionTitle: {
      type: String,
      default: 'Pricing Section',
    },
    sectionSubtitle: {
      type: String,
      default: 'Our prices',
    },
    sectionDescription: {
      type: String,
      default: 'Explore our pricing options...',
    },
    priceOptions: {
      type: Array,
      default: () => [
        {
          title: 'Package 1',
          price: 99,
          currencySymbol: '$',
          perUnit: 'one off',
          description: 'Description of service...',
          buttonName: 'Buy Now',
          buttonLink: '#',
          recommended: false,
          popular: true,
        },
        {
          title: 'Package 2',
          price: 399,
          currencySymbol: '$',
          perUnit: 'one off',
          description: 'Description of service...',
          buttonName: 'Buy Now',
          buttonLink: '#',
          recommended: true,
          popular: false,
        },
        {
          title: 'Package 3',
          price: 1399,
          currencySymbol: '$',
          perUnit: 'one off',
          description: 'Description of service...',
          buttonName: 'Buy Now',
          buttonLink: '#',
          recommended: false,
          popular: false,
        },
      ],
    },
  },
};
</script>

<style scoped>
/* Your scoped styles go here */
</style>
