<template>
  <section id="services" class="services">
    <div class="container">

      <div class="section-title" data-aos="zoom-in">
        <h2>{{ sectionTitle }}</h2>
        <h3>{{ sectionSubtitle }}</h3>
        <p>{{ sectionDescription }}</p>
      </div>

      <div class="row">
        <div v-for="(service, index) in services" :key="index" class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box" :data-aos="'zoom-in'" :data-aos-delay="index * 100">
            <h4><a :href="service.link">{{ service.title }}</a></h4>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  props:{
    sectionTitle: {
      type: String,
      default: 'Section title'
    },
    sectionSubtitle: {
      type: String,
      default: 'Section sectionSubtitle'
    },
    sectionDescription: {
      type: String,
      default: 'Section sectionDescription'
    },
    services: {
      type: Array,
      // default: [
      //   {
      //     title: 'Service 1',
      //     description: 'Service description',
      //     link: '#!'
      //   }
      // ]
    },
  },
};
</script>
