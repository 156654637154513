<template>
  <section id="testimonials" class="testimonials">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{ sectionTitle }}</h2>
        <p>{{ sectionDescription }}</p>
      </div>

      <div class="testimonials-carousel swiper-container">
        <div class="swiper-wrapper">

          <div class="swiper-slide">
            <div class="testimonial-item" data-aos="fade-up" data-aos-delay="100">
              <p>{{ testimonials[0].content }}</p>
              <h3>{{ testimonials[0].author }}</h3>
            </div>
          </div>

          <div class="swiper-slide">
            <div class="testimonial-item" data-aos="fade-up" data-aos-delay="100">
              <p>{{ testimonials[1].content }}</p>
              <h3>{{ testimonials[1].author }}</h3>
            </div>
          </div>

          <!-- Add more slides as needed -->

        </div>
        <div class="swiper-pagination"></div>
      </div>

    </div>
  </section>
</template>

<script>

import Swiper from 'swiper'; // Import Swiper
import 'swiper/swiper-bundle.css'; // Import Swiper styles

export default {
  props: {
    sectionTitle: {
      type: String,
      default: 'Testimonials',
    },
    sectionDescription: {
      type: String,
      default: 'What our clients say about us',
    },
    testimonials: {
      type: Array,
      default: () => [
        { content: 'Ahead of our wedding, my now wife and I signed up for 4 one hour dance lessons with Tim. We had no dancing experience but with Tim\'s fantastic tuition and encouragement we had great fun learning and were able to dazzle our guests with a memorable first dance. We\'d thoroughly recommend Dance Till Dawn and particularly Tim to everyone!', author: 'Mr & Mrs Vaughan' },
        { content: 'We both found lessons tremendously enjoyable! Tim has an infectious personality that shines through during his teaching. The Choreography, for the song that we chose, was spot on and fitted us perfectly. His explanation of the moves was easy to understand which made it easier to execute. We both consider Tim to be an excellent dance tutor who we would happily recommend to anyone.', author: 'Neil & Nita' },
        // Add more testimonials as needed
      ],
    },
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      new Swiper('.testimonials-carousel', {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    },
  },
};
</script>

<style scoped>
/* Your scoped styles go here */
</style>
