<template>
  <section id="portfolio" class="portfolio">
    <div class="container">

      <div class="section-title" data-aos="zoom-in">
        <h2>{{ sectionTitle }}</h2>
        <h3>{{ sectionSubtitle }}</h3>
        <p>{{ sectionDescription }}</p>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
        <div v-for="(item, index) in portfolioItems" :key="index" :class="['col-lg-4', 'col-md-6', 'portfolio-item', `filter-${item.filter}`]">
          <img :src="require(`@/assets/img/portfolio/${item.image}`)" class="img-fluid" alt="">
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  props: {
    sectionTitle: String,
    sectionSubtitle: String,
    sectionDescription: String,
    portfolioItems: Array,
  },
};
</script>
