<template>
    <div class="img-slider">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(image, index) in images" :key="index" class="swiper-slide">
            <div class="image-container">
              <img :src="require(`@/assets/img/${image}`)" class="img-fluid swiper-image" alt="">
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div v-if="navigation" class="swiper-pagination"></div>
        <!-- Add Navigation -->
        <div v-if="pagination">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Swiper from 'swiper/bundle';
  // import 'swiper/swiper-bundle.css';
  
  export default {
    props: {
      images: {
        type: String,
      },
      loop: {
        type: Boolean,
      },
      pagination: {
        type: Boolean,
      },
      navigation: {
        type: Boolean,
      },
    },
    mounted() {
      this.initSwiper();
    },
    methods: {
      initSwiper() {
        new Swiper('.swiper-container', {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: this.$props.loop,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
          autoplay: {
            delay: 3000,
          },
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Set a fixed height for the swiper-container */
  .swiper-container {
    height: 550px; /* Set your desired fixed height */
    overflow: hidden;
    position: relative;
  }
  
  .image-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .swiper-button-next,.swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: #8fc04e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    
  }
  
.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 12px !important;
}

  .swiper-button-next {
    right: 10px;
  }
  
  .swiper-button-prev {
    left: 10px;
  }
  
  .swiper-pagination {
    position: absolute !important;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  
  .swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #8fc04e !important;
    background-color: #8fc04e;

  }
  </style>
  