<template>
    <footer id="footer">
  
      <div class="footer-top" v-if="showNewsfeed">
  
        <div class="container">
  
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <h3>{{ websiteName }}</h3>
              <p>{{ websiteDescription }}</p>
            </div>
          </div>
  
          <div class="row footer-newsletter justify-content-center">
            <div class="col-lg-6">
              <form action="" method="post">
                <input type="email" name="email" placeholder="Enter your Email"><input type="submit" value="Subscribe">
              </form>
            </div>
          </div>
  
          <div class="social-links">
            <a :href="twitterUrl" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a :href="facebookUrl" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a :href="instagramUrl" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a :href="googlePlusUrl" class="google-plus"><i class="bx bxl-skype"></i></a>
            <a :href="linkedinUrl" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
  
        </div>
        
      </div>
  
      <div class="container footer-bottom clearfix">
        <div class="copyright">
          &copy; Copyright <strong><span>{{ websiteName }}</span></strong>. All Rights Reserved
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        websiteName: 'DanceTillDawn',
        websiteDescription: 'Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.',
        showNewsfeed: false,  // Set to false if you want to hide the newsfeed block
        twitterUrl: 'your_twitter_url',
        facebookUrl: 'your_facebook_url',
        instagramUrl: 'your_instagram_url',
        googlePlusUrl: 'your_googleplus_url',
        linkedinUrl: 'your_linkedin_url',
      };
    },
  };
  </script>
  
  <style>
  /* Your styles go here */
  </style>
  