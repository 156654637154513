<template>
  <section id="hero" class="d-flex align-items-center">
    <!-- <div class="w-100">
      <ImageSwiper :images="images_arr" :pagination="true" :navigation="true" :controls="false" :loop="true"/>
    </div> -->
    <div class="container text-center position-relative aos-init aos-animate" data-aos="fade-in" data-aos-delay="100">
      <h1>{{ pageTitle }}</h1>
      <h2>{{ pageSubtitle }}</h2>
      <a :href="pageLink" class="btn-get-started">{{ linkText }}</a>
    </div>
  </section>
</template>

<script>
// import ImageSwiper from '@/components/ImageSwiper.vue'


export default {
  components: {
    // ImageSwiper,
  },
  data(){
    return {
      images_arr: ['about.jpg', 'about2.jpg'],
    }
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Your Page Title',
    },
    pageSubtitle: {
      type: String,
      default: 'Your Page Subtitle',
    },
    pageLink: {
      type: String,
      default: '#',
    },
    linkText: {
      type: String,
      default: 'Get Started',
    },
  },
};
</script>

<style>
/* Your styles go here */
</style>
