<template>

  <TopBar />
  <HeaderBlock />
  <PageHero
      :pageTitle="heroTitle"
      :pageSubtitle="heroSubtitle"
      :pageLink="heroLink"
      :linkText="heroLinkText"
    />

  
  <AboutMe 
      :images_arr="images_arr"
      :aboutDescription="aboutDescription"
      :aboutSubtitle="aboutSubtitle"
      :iconBoxes="iconBoxes"

  />
  <CallToAction
      :ctaTitle="callToActionTitle"
      :ctaDescription="callToActionDescription"
      :ctaButtonName="callToActionButtonName"
      :ctaButtonLink="callToActionButtonLink"
    />
  <ServicesSection
      :sectionTitle="service_sectionTitle"
      :sectionSubtitle="service_sectionSubtitle"
      :sectionDescription="service_sectionDescription"
      :services="services"
  />
  <HighlightsSection 
      :sectionTitle="highlights_sectionTitle"
      :sectionSubtitle="highlights_sectionSubtitle"
      :sectionDescription="highlights_sectionDescription"
      :portfolioItems="portfolioItems"
    />
  
  <video-section
    :videoSource="videoPath"
  />
  <TestimonialsSection />
  <PricesSection 
      :sectionTitle="pricing_sectionTitle"
      :sectionSubtitle="pricing_sectionSubtitle"
      :sectionDescription="pricing_sectionDescription"
      :priceOptions="priceOptions"
  />
  <ContactSection
      :locationAddress="locationAddress"
      :emailAddress="emailAddress"
      :phoneNumber="phoneNumber"
      :sendMailUrl="sendMailUrl"
  />
  <SiteFooter />

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center" :class="{ 'active': isBackToTopVisible }">
      <i class="bi bi-arrow-up-short"></i>
    </a>


</template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap-icons/font/bootstrap-icons.css';

import TopBar from './components/TopBar.vue'
import HeaderBlock from './components/HeaderBlock.vue'
import PageHero from './components/PageHero.vue'
import AboutMe from './components/AboutMe.vue'
import CallToAction from './components/CallToAction.vue'
import ServicesSection from './components/ServicesSection.vue'
import HighlightsSection from './components/HighlightsSection.vue'
import VideoSection from './components/VideoSection.vue'
import TestimonialsSection from './components/TestimonialsSection.vue'
import PricesSection from './components/PricesSection.vue'
import ContactSection from './components/ContactSection.vue'
import SiteFooter from './components/SiteFooter.vue'

import data from './data2.json';

export default {
  name: 'App',
  data() {
    return {
      //global
      isBackToTopVisible: false,
      ...data
    }
  },
  components: {
    TopBar,
    HeaderBlock,
    PageHero,
    AboutMe,
    CallToAction,
    ServicesSection,
    HighlightsSection,
    VideoSection,
    TestimonialsSection,
    PricesSection,
    ContactSection,
    SiteFooter,
  },
  methods: {
    handleScroll() {
      const scrollThreshold = 100; // Adjust as needed
      this.isBackToTopVisible = window.scrollY > scrollThreshold;
    },
  },
  mounted() {
    AOS.init();
    window.addEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>

.back-to-top.active {
  opacity: 1;
}
</style>
