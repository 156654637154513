<template>
  <div id="topbar" class="d-flex align-items-center">
    <div class="container d-flex justify-content-center justify-content-md-between">

      <div class="contact-info d-flex align-items-center">
        <i class="bi bi-envelope pe-2"></i> <a :href="'mailto:' + email" class="pe-2 text-white">{{ email }}</a>
        <i class="bi bi-phone pe-2"></i> <a :href="'tel:' + phoneNumber" class="text-white">{{ phoneNumber }}</a>
      </div>

      <div class="social-links d-none d-md-flex">
        <a v-if="twitterUrl" :href="twitterUrl" target="_blank" class="twitter"><i class="bi bi-twitter"></i></a>
        <a v-if="facebookUrl" :href="facebookUrl" target="_blank" class="facebook"><i class="bi bi-facebook"></i></a>
        <a v-if="instagramUrl" :href="instagramUrl" target="_blank" class="instagram"><i class="bi bi-instagram"></i></a>
        <a v-if="googlePlusUrl" :href="googlePlusUrl" target="_blank" class="google-plus"><i class="bi bi-skype"></i></a>
        <a v-if="linkedinUrl" :href="linkedinUrl" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
      </div>


    </div>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  data() {
    return {
      email: 'dance_till_dawn@yahoo.com',
      phoneNumber: '+64 21 266 3644',
      facebookUrl: 'your_facebook_url',
      twitterUrl: null,
      instagramUrl: null,
      googlePlusUrl: null,
      linkedinUrl: null,
    };
  },
};
</script>

<style>
/* Your styles go here */
</style>
